.complex {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
}

.complex-title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.complex-title__head {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    text-align: left;
}

.complex-title__link {
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: rgba(30, 30, 30, 0.5);
    transition: color 0.3s;
}

.complex-title__link:hover {
    color: #1e1e1e;
}

.complex-grid {
    max-width: 1440px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.complex-grid__item {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.complex-grid__item-link {
    text-decoration: none;
    color: #1e1e1e;
}

.complex-grid__img {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 40px;
    overflow: clip;
}

.complex-grid__img-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s;
}

.complex-grid__img-picture:hover {
    transform: scale(1.3);
}

.complex-grid__text {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.complex-grid__text--adress,
.complex-grid__text--price {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.complex-grid_text_bottom {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #3d3d3d;
}

.complex-grid__img-text {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
}

.complex-grid__img-text-status {
    width: 126px;
    height: 32px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgba(0, 0, 0, 0.5);
    color: var(--basic-white);
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    backdrop-filter: blur(2px);
}

.complex-grid__img-text-status--red {
    height: inherit;
    color: #ba4141;
    font-size: 24px;
}

.complex-grid__img-text-status--green {
    height: inherit;
    color: #76ba41;
    font-size: 24px;
}

.complex__item-link::before {
    content: url('../../../images/main/icon.svg');
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: -100px;
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    transition: bottom 0.3s ease;
    backdrop-filter: blur(2px);
}

.complex__item-link:hover::before {
    bottom: 25px;
}

@media (max-width: 1100px) {
    .complex-grid__img {
        height: 406px;
    }

    .complex-grid__img-text__status {
        width: 100px;
    }

    .complex-grid__text {
        flex-direction: column;
        gap: 8px;
    }
}

@media (max-width: 1097px) {
    .complex-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 900px) {
    .complex__item-link::before {
        display: none;
    }

    .complex-grid__img-picture:hover {
        transform: none;
    }
}

@media (max-width: 601px) {
    .complex {
        gap: 32px;
    }

    .complex-grid__img-text__status {
        width: 120px;
    }

    .complex-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .complex-title__head {
        width: 70%;
        font-size: 42px;
    }

    .complex-title__link {
        font-size: 16px;
    }

    .complex {
        margin-bottom: 64px;
    }
}

@media (max-width: 451px) {
    .complex-title {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}
